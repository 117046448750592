<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk11Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk11',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-中粮招标'
    getBulk11Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '项目标号', value: res[0].projectCode},
        {text: '项目名称', value: res[0].projectName},
        {text: '报名截止时间', value: res[0].endTime},
        {text: '备注', value: res[0].remarks},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
